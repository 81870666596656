import * as user from './User'
import * as staff from './staff'
import * as permission from './permission'
import * as device from './device'
import * as material from './material'
import * as custom from './custom'
import * as target from './target'
import * as notice from './notice'
import * as technology from './technology'
import * as data from './data'
import * as form from './form'
import * as common from './common'
import * as workbench from './workbench'
import * as work from './work'
import * as category from './category'
import * as model from './model'
import * as bpm from './bpm'
import * as bpmFlowPath from './bpmFlowPath'
import * as dataLink from './dataLink'
import * as survey from './survey'

export default {
    user,
    staff,
    permission,
    device,
    material,
    custom,
    target,
    notice,
    technology,
    common,
    workbench,
    work,
    data,
    form,
    category,
    model,
    bpm,
    bpmFlowPath,
    dataLink,
    survey,
}
