export default {
    path: '/admin',
    name: 'admin',
    redirect: '/admin/home',
    component: () => import('@/views/admin/layout.vue'),
    children: [
        {
            path: '/admin/home',
            name: 'adminHome',
            component: () => import('@/views/admin/dashboard.vue'),
            meta: {
                title: '概览',
                icon: 'dashboard'
            }
        },
        {
            path: '/admin/staff-org',
            name: 'adminStaffOrg',
            meta: {
                title: '人员管理',
                icon: 'dashboard'
            },
            redirect: '/admin/staff-org/staff',
            children: [
                {
                    path: '/admin/staff-org/org',
                    name: 'adminOrg',
                    component: () => import('@/views/admin/staff/org/index.vue'),
                    meta: {
                        title: '部门管理'
                    }
                },
                {
                    path: '/admin/staff-org/staff',
                    name: 'adminStaff',
                    component: () => import('@/views/admin/staff/staff/index.vue'),
                    meta: {
                        title: '员工管理'
                    },
                    children: [
                        {
                            path: '/admin/staff-org/staff/:staffId',
                            name: 'staffDetail',
                            component: () => import('@/views/admin/staff/staff/staffInfo.vue'),
                            meta: {
                                title: '员工详情',
                                isHide: true
                            },
                            hidden: true
                        },
                    ]
                },
                {
                    path: '/admin/staff-org/position',
                    name: 'adminPosition',
                    component: () => import('@/views/admin/staff/position/index.vue'),
                    meta: {
                        title: '岗位管理'
                    }
                }
            ]
        },
        {
            path: '/admin/permission',
            name: 'adminPermission',
            meta: {
                title: '权限管理',
                icon: 'dashboard'
            },
            redirect: '/admin/permission/role',
            children: [
                {
                    path: '/admin/permission/role',
                    name: 'adminPermissionRole',
                    component: () => import('@/views/admin/permission/role/index.vue'),
                    meta: {
                        title: '角色管理'
                    }
                },
                {
                    path: '/admin/permission/member',
                    name: 'adminPositionMember',
                    component: () => import('@/views/admin/permission/member/index.vue'),
                    meta: {
                        title: '成员管理'
                    }
                }
            ]
        },
        {
            path: '/admin/technology',
            name: 'adminTechnology',
            meta: {
                title: '工艺管理',
                icon: 'dashboard'
            },
            redirect: '/admin/technology/methods',
            children: [
                {
                    path: '/admin/technology/methods',
                    name: 'adminTechnologyMethods',
                    component: () => import('@/views/admin/technology/methods/index.vue'),
                    meta: {
                        title: '工艺方法管理'
                    }
                },
                {
                    path: '/admin/technology/accessory',
                    name: 'accessory',
                    meta: {
                        title: '工艺配方管理'
                    },
                    component: () => import('@/views/admin/technology/accessory/index.vue'),
                    children: [
                        {
                            path: '/admin/technology/accessory/:id',
                            name: 'accessoryDetail',
                            component: () => import('@/views/admin/technology/accessory/edit.vue'),
                            meta: {
                                title: '配方详情',
                                isHide: true
                            },
                            hidden: true
                        },
                    ]
                },
                {
                    path: '/admin/technology/procedure',
                    name: 'procedure',
                    meta: {
                        title: '工艺工序管理'
                    },
                    component: () => import('@/views/admin/technology/procedure/index.vue'),
                    children: [
                        {
                            path: '/admin/technology/procedure/:id',
                            name: 'procedureDetail',
                            component: () => import('@/views/admin/technology/procedure/edit.vue'),
                            meta: {
                                title: '工序详情',
                                isHide: true
                            },
                            hidden: true
                        },
                    ]
                },
                {
                    path: '/admin/craft/template',
                    name: 'adminCraftTemplate',
                    meta: {
                        title: '工艺流程管理'
                    },
                    component: () => import('@/views/admin/craft/template.vue'),
                    children: [
                        {
                            path: '/admin/craft/template/:id',
                            name: 'adminCraftTemplateEdit',
                            meta: {
                                title: '编辑工艺',
                                isHide: true
                            },
                            hidden: true,
                            component: () => import('@/views/admin/craft/edit.vue'),
                        }
                    ]
                },
                {
                    path: '/admin/technology/workOrder',
                    name: 'workOrder',
                    meta: {
                        title: '作业工单列表'
                    },
                    component: () => import('@/views/admin/technology/workOrder/index.vue'),

                },

                {
                    path: '/admin/technology/baseConfig',
                    name: 'baseConfig',
                    meta: {
                        title: '工艺基础信息'
                    },
                    component: () => import('@/views/admin/technology/baseConfig/index.vue'),

                }
            ]
        },
        {
            path: '/admin/device',
            name: 'adminDevice',
            meta: {
                title: '设备管理',
                icon: 'dashboard'
            },
            redirect: '/admin/device/list',
            children: [
                {
                    path: '/admin/device/library',
                    name: 'adminDeviceLibrary',
                    component: () => import('@/views/admin/device/library/index.vue'),
                    meta: {
                        title: '设备类型管理'
                    }
                },
                {
                    path: '/admin/device/list',
                    name: 'adminDeviceList',
                    component: () => import('@/views/admin/device/device/index.vue'),
                    meta: {
                        title: '设备库存管理'
                    }
                },
            ]
        },
        {
            path: '/admin/material',
            name: 'adminMaterial',
            meta: {
                title: '物料管理',
                icon: 'dashboard'
            },
            redirect: '/admin/material/list',
            children: [
                {
                    path: '/admin/material/library',
                    name: 'adminMaterialLibrary',
                    component: () => import('@/views/admin/material/library/index.vue'),
                    meta: {
                        title: '物料基本信息'
                    }
                },
                {
                    path: '/admin/material/list',
                    name: 'adminMaterialList',
                    component: () => import('@/views/admin/material/material/index.vue'),
                    meta: {
                        title: '物料库存管理'
                    }
                }
            ]
        },
        // {
        //     path: '/admin/data-metrics',
        //     name: 'adminData',
        //     meta: {
        //         title: '数据指标',
        //         icon: 'dashboard'
        //     },
        //     redirect: '/admin/data-metrics/list',
        //     children: [
        //         {
        //             path: '/admin/data-metrics/list',
        //             name: 'dataMetrics',
        //             component: () => import('@/views/admin/data/library/index.vue'),
        //             meta: {
        //                 title: '指标库'
        //             }
        //         },
        //
        //     ]
        // },
        {
            path: '/admin/task',
            name: 'adminTask',
            meta: {
                title: '任务管理',
                icon: 'dashboard'
            },
            component: () => import('@/views/admin/task/index.vue'),
        },
        {
            path: '/admin/custom',
            name: 'adminCustom',
            meta: {
                title: '客户管理',
                icon: 'dashboard'
            },
            component: () => import('@/views/admin/custom/custom/index.vue'),
        },
        {
            path: '/admin/bpm',
            name: 'adminBPM',
            meta: {
                title: '流程管理',
                icon: 'dashboard'
            },
            children: [
                {
                    path: '/admin/form/model',
                    name: 'adminFormModel',
                    meta: {
                        title: '审批模型管理'
                    },
                    component: () => import('@/views/admin/form/model/index.vue'),
                    children: [
                        {
                            path: '/admin/form/model/:id',
                            name: 'formModelDetail',
                            component: () => import('@/views/admin/form/model/edit.vue'),
                            meta: {
                                title: '模型详情',
                                isHide: true
                            },
                            hidden: true
                        },
                    ]
                },
                {
                    path: '/admin/form/form',
                    name: 'adminFormForm',
                    component: () => import('@/views/admin/form/form/index.vue'),
                    meta: {
                        title: '审批表单管理'
                    },
                    children: [
                        {
                            path: '/admin/form/form/:id',
                            name: 'formDetail',
                            component: () => import('@/views/admin/form/form/edit.vue'),
                            meta: {
                                title: '表单详情',
                                isHide: true
                            },
                            hidden: true
                        },
                    ]
                },
                {
                    path: '/admin/bpm/template',
                    name: 'adminBpmTemplate',
                    component: () => import('@/views/admin/bpm/template/index.vue'),
                    meta: {
                        title: '审批模板管理'
                    },
                    children: [
                        {
                            path: '/admin/bpm/template/:id',
                            name: 'bpmTemplateDetail',
                            component: () => import('@/views/admin/bpm/template/edit.vue'),
                            meta: {
                                title: '模板详情',
                                isHide: true
                            },
                            hidden: true
                        },
                    ]
                },
                {
                    path: '/admin/bpm/example',
                    name: 'adminBpmExample',
                    meta: {
                        title: '审批流程列表'
                    },
                    component: () => import('@/views/admin/form/example/index.vue'),
                },
            ]
        },
        // {
        //     path: '/admin/target',
        //     name: 'adminTarget',
        //     meta: {
        //         title: '目标管理',
        //         icon: 'dashboard'
        //     },
        //     redirect: '/admin/target/list',
        //     children: [
        //         {
        //             path: '/admin/target/list',
        //             name: 'adminTargetList',
        //             component: () => import('@/views/admin/target/target/index.vue'),
        //             meta: {
        //                 title: '目标设置'
        //             }
        //         }
        //     ]
        // },
        {
            path: '/admin/notice',
            name: 'adminNotice',
            meta: {
                title: '系统管理',
                icon: 'dashboard'
            },
            children: [
                {
                    path: '/admin/notice/list',
                    name: 'adminNoticeList',
                    component: () => import('@/views/admin/notice/notice/index.vue'),
                    meta: {
                        title: '公告列表'
                    }
                },
                {
                    path: '/admin/datalink/list',
                    name: 'adminDatalinkList',
                    component: () => import('@/views/admin/system/datalink/index.vue'),
                    meta: {
                        title: 'CEO的十张表'
                    }
                },
                {
                    path: '/admin/import/index',
                    name: 'adminImportIndex',
                    component: () => import('@/views/admin/import/index.vue'),
                    meta: {
                        title: '数据导入'
                    }
                }
            ]
        },
        {
            path: '/admin/survey',
            name: 'adminSurvey',
            meta: {
                title: '问卷管理',
                icon: 'dashboard'
            },
            children: [
                {
                    path: '/admin/survey/list',
                    name: 'adminSurveyList',
                    component: () => import('@/views/admin/survey/survey/index.vue'),
                    meta: {
                        title: '问卷列表'
                    },
                    children: [
                        {
                            path: '/admin/survey/:id',
                            name: 'surveyDetail',
                            component: () => import('@/views/admin/survey/survey/edit.vue'),
                            // component: () => import('@/views/admin/bpm/template/edit.vue'),
                            meta: {
                                title: '编辑问卷',
                                // isHide: true
                            },
                            hidden: true
                        },
                    ]
                },
                {
                    path: '/admin/answer/list',
                    name: 'adminDatalinkList',
                    component: () => import('@/views/admin/survey/answer/index.vue'),
                    meta: {
                        title: '结果列表'
                    }
                },
                // {
                //     path: '/admin/import/index',
                //     name: 'adminImportIndex',
                //     component: () => import('@/views/admin/import/index.vue'),
                //     meta: {
                //         title: '数据导入'
                //     }
                // }
            ]
        },
        // {
        //     path: '/admin/system',
        //     name: 'system',
        //     meta: {
        //         title: '系统管理',
        //         icon: 'dashboard'
        //     },
        //     children: [
        //         {
        //             path: '/admin/system/config',
        //             name: 'systemCofig',
        //             component: () => import('@/views/admin/system/index.vue'),
        //             meta: {
        //                 title: '工作台配置'
        //             }
        //         }
        //     ]
        // }
    ]
}
